@import '~antd/dist/antd.less';
@import "~jsondiffpatch/dist/formatters-styles/html.css";

#components-layout-demo-top .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}
.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 16px 0 16px 24px;
}


tr.downward td {
  border-bottom: 2px dashed #B56357;
}

tr.upward td {
  border-top: 2px dashed #B56357;
}
@primary-color: #B56357;@layout-header-background: #000000;